// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import Home from './pages/Home';
import Trade from './pages/Trade';
import Balance from './pages/Balance';
import Profile from './pages/Profile';
import Login from './auth/Login';
import Logout from './auth/Logout';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import Header from './components/Header'; // Importa el nuevo componente Header
import LuckyWheel from './pages/LuckyWheel';
import Referral from './pages/Referral'; // Importa el nuevo componente Referral  

import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="app">
          <Header /> {/* Utiliza el componente Header */}

          {/* Rutas */}
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/trade" element={<Trade />} />
              <Route path="/balance" element={<Balance />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/lucky-wheel" element={<LuckyWheel />} />
              <Route path='/referral' element={<Referral />} />
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { Chart as ChartJS, CategoryScale, LinearScale, TimeScale, Tooltip } from "chart.js";
import { CandlestickController, CandlestickElement } from "chartjs-chart-financial";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import "../styles/TradeModal.css";

// 📊 Registrar componentes de Chart.js
ChartJS.register(CategoryScale, LinearScale, TimeScale, CandlestickController, CandlestickElement, Tooltip);

const TradeModal = ({ crypto, balance, onClose, onTrade }) => {
  const [operationAmount, setOperationAmount] = useState("");
  const [countdown, setCountdown] = useState(null); // ⏳ Contador antes de operar
  const [isBlocked, setIsBlocked] = useState(false); // 🔒 Bloqueo mientras se opera
  const [isProcessing, setIsProcessing] = useState(false); // 🚀 Evita múltiples ejecuciones
  const currencySymbol = "$";

  useEffect(() => {
    if (crypto) {
      setOperationAmount(""); // Resetear input al cambiar cripto
      setIsBlocked(false);
      setIsProcessing(false);
      setCountdown(null);
    }
  }, [crypto]);

  // ⏳ Iniciar cuenta regresiva de 60 segundos
  const startPreOperationCountdown = (type) => {
    if (isProcessing) return; // 🚫 Evitar doble ejecución
  
    if (!operationAmount || isNaN(operationAmount) || parseFloat(operationAmount) <= 0) {
      alert("Ingresa un monto válido mayor a 0.");
      return;
    }
  
    setIsProcessing(true); // 🚀 En proceso
    setIsBlocked(true); // 🔒 Bloqueado
  
    let seconds = 60; // ⏱️ Ahora 60 segundos
    setCountdown(seconds);
  
    const interval = setInterval(() => {
      seconds--;
      setCountdown(seconds);
  
      if (seconds === 0) {
        clearInterval(interval);
        executeTrade(type); // Ejecutar al finalizar
      }
    }, 1000);
  };

  // ✅ Ejecutar operación
  const executeTrade = async (type) => {
  console.log("Enviando datos a la API:", {
    criptomoneda: crypto.id,
    tipo: type,
    monto: parseFloat(operationAmount)
  });

  try {
    await onTrade(type, operationAmount); // 🔥 Operar

    setTimeout(() => {
      setIsBlocked(false); // Desbloquear
      setIsProcessing(false);
      onClose(); // Cerrar modal
    }, 500);
  } catch (error) {
    console.error("Error en la operación:", error);
    setIsProcessing(false);
    setIsBlocked(false);
  }
};

  // ✅ Asignar porcentaje del balance
  const handleSetPercentage = (percent) => {
    const amount = ((balance * percent) / 100).toFixed(2);
    setOperationAmount(amount);
  };

  // 📈 Datos para gráfico OHLC
  const ohlcData = crypto?.ohlc?.slice(-20).map((entry) => ({
    x: new Date(entry.x),
    o: entry.o,
    h: entry.h,
    l: entry.l,
    c: entry.c,
  })) || [];

  const chartData = {
    datasets: [
      {
        label: `Precio de ${crypto.name} (Últimos 20 días)`,
        data: ohlcData,
        borderColor: "#ffffff",
        backgroundColor: "transparent",
        color: {
          up: "#4caf50",
          down: "#f44336",
          unchanged: "#888888",
        },
        borderWidth: 1.5,
        barThickness: 8,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false } },
    scales: {
      x: {
        type: "time",
        time: { unit: "day", tooltipFormat: "MMM dd" },
        ticks: { color: "#fff", font: { weight: "bold" } },
        grid: { display: false },
      },
      y: {
        ticks: { color: "#fff", font: { weight: "bold" } },
        grid: { color: "#555" },
      },
    },
  };

  return (
    <div className="trade-modal-overlay">
      <div className={`trade-modal-container ${isBlocked ? "blur-background" : ""}`}>
        {/* 🔒 Bloqueo de pantalla durante countdown */}
        {isBlocked && (
          <div className="trade-modal-blocked">
            ⏳ Wait {countdown} seconds...
          </div>
        )}

        <button
          className="trade-close-button"
          onClick={onClose}
          aria-label="Cerrar Modal"
          disabled={isBlocked}
        >
          <FaTimes />
        </button>

        <h2 className="trade-modal-title">Trading {crypto.name}</h2>

        {/* 📈 Gráfico de Velas OHLC */}
        <div className="trade-chart">
          {crypto.ohlc ? (
            <Chart type="candlestick" data={chartData} options={chartOptions} />
          ) : (
            <p className="trade-chart-placeholder">             
                No price data available.
            </p>
          )}
        </div>

        {/* Balance y monto de operación */}
        <p className="trade-balance-info">
          Available Balance:{" "}
          <strong style={{ color: "green" }}>${balance.toFixed(2)}</strong>
        </p>
        <input
          type="number"
          className="trade-modal-input"
          value={operationAmount}
          onChange={(e) => setOperationAmount(e.target.value)}
          placeholder={`Amount (${currencySymbol})`}
          disabled={isBlocked}
        />
        {/* Botones de porcentaje */}
        <div className="trade-percentage-buttons">
          {[25, 50, 75, 100].map((percent) => (
            <button
              key={percent}
              className="trade-percentage-button"
              onClick={() => handleSetPercentage(percent)}
              disabled={isBlocked}
            >
              {percent}%
            </button>
          ))}
        </div>

        {/* Botones de acción */}
        <div className="trade-modal-actions">
          <button
            className="trade-action-button trade-buy-button"
            onClick={() => startPreOperationCountdown("compra")}
            disabled={isBlocked || isProcessing}
          >
            BUY
          </button>
          <button
            className="trade-action-button trade-sell-button"
            onClick={() => startPreOperationCountdown("venta")}
            disabled={isBlocked || isProcessing}
          >
            SELL
          </button>
        </div>
      </div>
    </div>
  );
};

export default TradeModal;

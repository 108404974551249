import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { FaTelegram } from "react-icons/fa";

const SupportModal = ({ open, onClose }) => {
  const supportNumbers = [
    "https://t.me/POLARISSUPP1",
    "https://t.me/POLARISSUPP2",
    "https://t.me/POLARISSUPP3",
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* Main message */}
        <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
          You can contact our support team by selecting one of the options below:
        </Typography>

        {/* Telegram icons aligned */}
        <Box sx={iconContainer}>
          {supportNumbers.map((link, index) => (
            <a
              key={index}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <FaTelegram size={50} color="#0088cc" />
            </a>
          ))}
        </Box>

        {/* Support via email */}
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ mt: 3, fontWeight: "bold" }}
        >
          Having trouble reaching us?
        </Typography>
        <Typography variant="body2" textAlign="center">
          Email us at:{" "}
          <a href="mailto:support@polarisex.com" style={{ color: "#00aaff" }}>
            support@polarisex.com
          </a>
        </Typography>
      </Box>
    </Modal>
  );
};

// Modal styles
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#0a0f1f",
  color: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

/* Icon container */
const iconContainer = {
  display: "flex",
  justifyContent: "center",
  gap: "20px",
};

export default SupportModal;

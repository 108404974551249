import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import api from "../services/api";
import { Modal, Table, Button } from "react-bootstrap";
import "../styles/Balance.css";

const Balance = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [referidos, setReferidos] = useState(0);
  const [operaciones, setOperaciones] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showOperacionesModal, setShowOperacionesModal] = useState(false);
  const [operacionesList, setOperacionesList] = useState([]);

  const [balance, setBalance] = useState(() => Number(0));
  const [saldoRecompensas, setSaldoRecompensas] = useState(() => Number(0));
  const [totalGanado, setTotalGanado] = useState(() => Number(0));
  const [totalRetirado, setTotalRetirado] = useState(() => Number(0));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };

      const balanceResponse = await api.get("/balance/balance", config);
      if (balanceResponse.data.success) {
        setBalance(Number(balanceResponse.data.data?.saldo) || 0);
        setSaldoRecompensas(Number(balanceResponse.data.data?.saldo_recompensas) || 0);
      } else throw new Error(balanceResponse.data.error);

      const referidosResponse = await api.get("/referidos/referidos", config);
      if (referidosResponse.data.success) {
        setReferidos(referidosResponse.data.data?.length || 0);
      }

      const operacionesResponse = await api.get("/operacion/operaciones", config);
      if (operacionesResponse.data.success) {
        const ops = (operacionesResponse.data.data || []).map(op => ({
          ...op,
          monto: Number(op.monto) || 0,
          ganancia: Number(op.ganancia) || 0,
        }));
        setOperaciones(ops.length);
        setOperacionesList(ops);
      } else {
        setOperacionesList([]); // Si no es success, poner array vacío
      }

      const ganadoResponse = await api.get("/balance/total-ganado", config);
      if (ganadoResponse.data.success) {
        setTotalGanado(Number(ganadoResponse.data.data?.total_ganado) || 0);
      }

      const retiradoResponse = await api.get("/balance/total-retirado", config);
      if (retiradoResponse.data.success) {
        setTotalRetirado(Number(retiradoResponse.data.data?.total_retirado) || 0);
      }

      setError(null);
    } catch (err) {
      console.error("Fetch error:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) fetchData();
  }, [isAuthenticated, fetchData]);

  const handleTransfer = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await api.post("/balance/transfer-rewards", {}, config);
      if (response.data.success) {
        setSuccess("Reward balance transferred successfully.");
        setShowModal(false);
        fetchData();
      } else throw new Error(response.data.error);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccess(null);
        setError(null);
      }, 3000);
    }
  };

  return (
    <div className="balance-container">
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      {success && <div className="notification success">{success}</div>}

      {!loading && !error && (
        <>
          <div className="grid-layout">
            <div className="custom-card">
              <h2>Current Balance</h2>
              <p>${Number(balance).toFixed(2)}</p>
            </div>
            <div className="custom-card">
              <h2>Reward Balance</h2>
              <p>${Number(saldoRecompensas).toFixed(2)}</p>
            </div>
            <div className="custom-card">
              <h2>Total Referrals</h2>
              <p>{referidos}</p>
            </div>
            <div className="custom-card">
              <h2>Total Trades</h2>
              <p>{operaciones}</p>
            </div>
            <div className="custom-card">
              <h2>Total Earned</h2>
              <p>${Number(totalGanado).toFixed(2)}</p>
            </div>
            <div className="custom-card">
              <h2>Total Withdrawn</h2>
              <p>${Number(totalRetirado).toFixed(2)}</p>
            </div>
          </div>

          <div className="button-row">
            <button
              className="action-button view-operations-button"
              onClick={() => setShowOperacionesModal(true)}
            >
              📊 View Trades
            </button>

            <button
              className={`action-button ${saldoRecompensas > 0 ? "deposit-button" : "disabled-button"}`}
              onClick={() => setShowModal(true)}
              disabled={saldoRecompensas <= 0}
            >
              Transfer Rewards
            </button>
          </div>
        </>
      )}

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to transfer your reward balance to your main balance?</p>
          <p><strong>Amount to transfer:</strong> ${saldoRecompensas.toFixed(2)}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="withdraw-button action-button" onClick={() => setShowModal(false)}>Cancel</button>
          <button className="deposit-button action-button" onClick={handleTransfer}>Confirm Transfer</button>
        </Modal.Footer>
      </Modal>

      {/* Trades Modal */}
      <Modal show={showOperacionesModal} onHide={() => setShowOperacionesModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Trade History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {operacionesList.length > 0 ? (
            <div className="responsive-table">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Cryptocurrency</th>
                    <th>Amount</th>
                    <th>Profit</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {operacionesList.map((op, index) => (
                    <tr key={op.id}>
                      <td>{op.tipo}</td>
                      <td>{op.criptomoneda}</td>
                      <td>${op.monto.toFixed(2)}</td>
                      <td>${op.ganancia.toFixed(2)}</td>
                      <td>{op.fecha}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No trades recorded.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOperacionesModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Balance;

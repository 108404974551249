import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api, { BASE_URL_REF } from "../services/api";
import "../styles/Referral.css";

// ✅ Importación de imágenes
import ReferralImage from "../assets/referrals.png";
import Bronze from "../assets/bronce.png";
import Silver from "../assets/silver.png";
import Gold from "../assets/GOLD.png";
import Diamond from "../assets/diomond.png";
import Platinum from "../assets/platinium.png";
import ProgressBar from "../assets/progress_bar.png";
import Blond from "../assets/blond.png";
import Dollar from "../assets/dollar.png";

const levels = [
  { icon: Bronze, referrals: 0, amount: "$0 - 50 USDT", percentage: "1.5%" },
  { icon: Silver, referrals: 5, amount: "$50 - 199 USDT", percentage: "1.8%" },
  { icon: Gold, referrals: 15, amount: "$199 - 999 USDT", percentage: "2.0%" },
  { icon: Diamond, referrals: 30, amount: "$1000 - 4999 USDT", percentage: "2.4%" },
  { icon: Platinum, referrals: 50, amount: "$5000+ USDT", percentage: "3.0%" },
];

const Referral = () => {
  const [referralCode, setReferralCode] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsAuthenticated(false);
      return;
    }

    const fetchReferralCode = async () => {
      try {
        const response = await api.get("/auth/perfil");
        if (response.data.success && response.data.data.codigo_referido) {
          setReferralCode(response.data.data.codigo_referido);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (err) {
        setIsAuthenticated(false);
      }
    };

    fetchReferralCode();
  }, []);

  const handleCopyToClipboard = () => {
    if (!referralCode) return;
    const referralLink = `${BASE_URL_REF}/register?ref=${referralCode}`;
    navigator.clipboard.writeText(referralLink).then(() => {
      alert("Referral code copied to clipboard!");
    });
  };

  return (
    <div className="referral-container">
      <h1>Referral & Level Program</h1>

      {/* Referral Link Section */}
      <div className="referral-box">
        {isAuthenticated ? (
          <>
            <p>Your Referral Code:</p>
            <div className="referral-code">{referralCode}</div>
            <button className="referral-copy-button" onClick={handleCopyToClipboard}>
              Copy Invitation Link
            </button>
          </>
        ) : (
          <button className="referral-login-button" onClick={() => navigate("/login")}>
            Login to Get Your Referral Code
          </button>
        )}
      </div>

      {/* 2 Columns Section */}
      <div className="referral-columns">
        {/* Left Column */}
        <div className="referral-column">
          <h2>Referral Program Explanation</h2>
          <img src={ReferralImage} alt="Referral Program" className="referral-image" />
        </div>

        {/* Right Column */}
        <div className="referral-column">
          <h2>Level Program Explanation</h2>
          {levels.map((level, index) => (
            <div className="level-card" key={index}>
              <img src={level.icon} alt={`Level ${index}`} className="medal-icon" />
              <div className="level-details">
                {/* Primera línea: Barra + icono + texto referrals */}
                <div className="level-line">
                  <img src={ProgressBar} alt="progress" className="progress-bar" />
                  <img src={Blond} alt="referrals" className="icon" />
                  <span>{level.referrals} REFERRALS</span>
                </div>

                {/* Segunda línea: Barra + icono + monto */}
                <div className="level-line">
                  <img src={ProgressBar} alt="progress" className="progress-bar" />
                  <img src={Dollar} alt="amount" className="icon" />
                  <span>{level.amount}</span>
                </div>

                {/* Porcentaje */}
                <div className="percentage-text">INCOME PERCENTAGE: {level.percentage}</div>
              </div>
            </div>
          ))}
        </div>


      </div>
    </div>
  );
};

export default Referral;

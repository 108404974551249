import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { FaSignOutAlt, FaSignInAlt, FaHeadset, FaBars, FaTimes, FaMoneyBillWave, FaWallet } from "react-icons/fa";
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import SupportModal from "./SupportModal";
import "./Header.css";
import logo from "../assets/logo1.png"; // ✅ Ruta de tu logo

const Header = () => {
  const { user, logout, isAuthenticated } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="header">
      <nav className="nav-custom">
        {/* Botón de menú hamburguesa */}
        <div className="menu-logo-container">
          <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Logo al lado derecho del menú hamburguesa en móvil */}
          <Link to="/" className="logo-link">
            <img src={logo} alt="Logo" className="logo-img" />
          </Link>
        </div>

        {/* Overlay para oscurecer el fondo cuando el menú está abierto */}
        {isMenuOpen && <div className="overlay" onClick={() => setIsMenuOpen(false)}></div>}

        {/* Menú lateral */}
        <ul className={`nav-list-custom ${isMenuOpen ? "open" : ""}`}>
          <li><Link to="/" className="nav-link-custom" onClick={() => setIsMenuOpen(false)}>HOME</Link></li>
          <li><Link to="/trade" className="nav-link-custom" onClick={() => setIsMenuOpen(false)}>TRADE</Link></li>
          <li><Link to="/balance" className="nav-link-custom" onClick={() => setIsMenuOpen(false)}>BALANCE</Link></li>
          <li><Link to="/profile" className="nav-link-custom" onClick={() => setIsMenuOpen(false)}>PROFILE</Link></li>
          <li><Link to="/referral" className="nav-link-custom" onClick={() => setIsMenuOpen(false)}>REFERRAL</Link></li>          
        </ul>

        {/* Botones alineados a la derecha */}
        <div className={`nav-buttons ${isMenuOpen ? "open" : ""}`}>
          {isAuthenticated ? (
            <>
              <button className="btn-deposit" onClick={() => { setOpenModal("deposit"); setIsMenuOpen(false); }}>
                <FaMoneyBillWave className="icon-button" />
                <span className="btn-text">DEPOSIT</span>
              </button>
              <button className="btn-withdraw" onClick={() => { setOpenModal("withdraw"); setIsMenuOpen(false); }}>
                <FaWallet className="icon-button" />
                <span className="btn-text">WITHDRAW</span>
              </button>
              <button className="btn-support" onClick={() => { setOpenModal("support"); setIsMenuOpen(false); }}>
                <FaHeadset className="icon-button" />
                <span className="btn-text">SUPPORT</span>
              </button>
              <div className="user-section">
                <span className="user-name">{user?.nombre}</span>
                <button className="btn-logout" onClick={() => { logout(); setIsMenuOpen(false); }}>
                  <FaSignOutAlt className="icon-button" />
                  <span className="btn-text">Logout</span>
                </button>
              </div>
            </>
          ) : (
            <Link to="/login" className="btn-login">
              <FaSignInAlt className="icon-button" />
              <span className="btn-text">LOGIN</span>
            </Link>
          )}
        </div>
      </nav>

      {/* Modales */}
      <DepositModal open={openModal === "deposit"} onClose={() => setOpenModal(null)} />
      <WithdrawModal open={openModal === "withdraw"} onClose={() => setOpenModal(null)} />
      <SupportModal open={openModal === "support"} onClose={() => setOpenModal(null)} />
    </header>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { IoShieldCheckmarkSharp, IoHelpCircleSharp, IoGift, IoPeople } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import "../styles/Home.css";
import api, { cryptoApi, BASE_URL } from "../services/api"; // 🔥 Importamos API
import SupportModal from "../components/SupportModal"; // Importamos el modal

const Home = () => {
  const [cryptos, setCryptos] = useState([]);
  const [loadingCryptos, setLoadingCryptos] = useState(true);
  const [news, setNews] = useState([]);
  const [loadingNews, setLoadingNews] = useState(true);
  const [openSupportModal, setOpenSupportModal] = useState(false); // Estado para abrir el modal

  useEffect(() => {
    fetchCryptos();
    fetchNews();
  }, []);

  const fetchCryptos = async () => {
    try {
      const response = await cryptoApi.get("/data/top/mktcapfull", {
        params: {
          limit: 6,
          tsym: "USD",
        },
      });

      const formattedData = response.data.Data.map((item) => ({
        id: item.CoinInfo.Id,
        name: item.CoinInfo.FullName,
        symbol: item.CoinInfo.Name,
        price: item.RAW.USD.PRICE,
        change24h: item.RAW.USD.CHANGEPCT24HOUR,
      }));

      setCryptos(formattedData);
    } catch (error) {
      console.error("Error al obtener criptomonedas:", error);
    } finally {
      setLoadingCryptos(false);
    }
  };

  const fetchNews = async () => {
    try {
      const response = await api.get("/news/news?status=active");
      setNews(response.data.data);
    } catch (error) {
      console.error("Error al obtener noticias:", error);
    } finally {
      setLoadingNews(false);
    }
  };

  const menuItems = [
    { icon: <FaChartLine />, label: "TRADE", tooltip: "Go to trade", route: "/trade" },
    { icon: <FaDownload />, label: "DOWNLOAD APK", tooltip: "Download our app", action: "download" },
    { icon: <IoHelpCircleSharp />, label: "TECHNICAL SUPPORT", tooltip: "Get help", action: "support" },
    //{ icon: <IoGift />, label: "LUCKY WHEEL", tooltip: "Try your luck", route: "/lucky-wheel" },
    { icon: <IoPeople />, label: "REFERRAL PROGRAM", tooltip: "Invite your friends", route: "/referral" },
  ];

  const handleAction = (action) => {
    if (action === "download") {
      window.location.href = "/assets/apk/myapp.apk";
    } else if (action === "support") {
      setOpenSupportModal(true); // 🔥 Abre el modal de soporte
    }
  };

  return (
    <div className="home-container">
      {/* Carrusel de Noticias */}
      <div className="home-news-section">
        {loadingNews ? (
          <p className="home-loading-text">Loading News...</p>
        ) : Array.isArray(news) && news.length > 0 ? ( // 🔥 Validamos que `news` sea un array
          <Carousel controls={true} indicators={true}>
            {news.map((item) => (
              <Carousel.Item key={item.id}>
                <img
                  className="d-block w-100 home-news-image"
                  src={`${BASE_URL}/${item.image_url}`}
                  alt={item.title || "Noticia"}
                />
                <Carousel.Caption>
                  <p>{item.description}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <p className="home-loading-text">No news available</p>
        )}
      </div>

      {/* Menú de Iconos */}
      <div className="home-menu-main">
        <div className="home-menu">
          {menuItems.map((item, index) => (
            <div key={index} className="home-menu-item">
              {item.route ? (
                <Link to={item.route} className="home-menu-link" aria-label={item.label}>
                  {React.cloneElement(item.icon, { size: 30 })}
                  <span className="home-tooltip">{item.tooltip}</span>
                </Link>
              ) : item.action ? (
                <button className="home-menu-button" onClick={() => handleAction(item.action)} aria-label={item.label}>
                  {React.cloneElement(item.icon, { size: 30 })}
                  <span className="home-tooltip">{item.tooltip}</span>
                </button>
              ) : (
                <div className="home-menu-icon">
                  {React.cloneElement(item.icon, { size: 30 })}
                  <span className="home-tooltip">{item.tooltip}</span>
                </div>
              )}
              <span className="home-menu-label">{item.label}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Lista de Criptomonedas */}
      <div className="home-crypto-section">
        <h2 className="home-section-title">TOP CRYPTOCURRENCY</h2>
        {loadingCryptos ? (
          <p className="home-loading-text">Loading...</p>
        ) : (
          <div className="home-crypto-list">
            {cryptos.map((item) => (
              <div key={item.id} className="home-crypto-item">
                <div className="home-crypto-info">
                  <span className="home-crypto-pair">{item.symbol}/USD</span>
                  <span className="home-crypto-price">${item.price.toFixed(2)}</span>
                </div>
                <span className={`home-crypto-change ${item.change24h > 0 ? "home-positive-change" : "home-negative-change"}`}>
                  {item.change24h.toFixed(2)}%
                </span>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* 🔥 Modal de Soporte */}
      <SupportModal open={openSupportModal} onClose={() => setOpenSupportModal(false)} />
    </div>
  );
};

export default Home;
import React, { useState } from 'react';
import api from '../services/api';
import "../styles/ForgotPassword.css";

const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    const [form, setForm] = useState({
        email: '',
        code: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    // ✅ Solicitar código al email
    const handleRequestCode = async (e) => {
        e.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        setIsLoading(true);
    
        try {
            const response = await api.post('/auth/recover-password', { email: form.email });
    
            if (response.data.success) {
                setSuccessMessage('Verification code sent to your email.');
                setStep(2);
            } else {
                // ✅ Capturamos específicamente "User not found"
                if (response.data.error === 'User not found.') {
                    setErrorMessage('This email is not registered.');
                } else {
                    setErrorMessage(response.data.error || 'Error requesting the code.');
                }
            }
        } catch (error) {
            // ✅ Revisamos si es error del backend o de conexión
            if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error === 'User not found.') {
                    setErrorMessage('This email is not registered.');
                } else {
                    setErrorMessage(error.response.data.error || 'Error requesting the code.');
                }
            } else {
                setErrorMessage('Failed to connect to the server. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // ✅ Restablecer la contraseña
    const handleResetPassword = async (e) => {
        e.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        setIsLoading(true);

        // ✅ Validar que las contraseñas coincidan
        if (form.newPassword !== form.confirmPassword) {
            setErrorMessage('Passwords do not match.');
            setIsLoading(false);
            return;
        }

        try {
            const payload = {
                email: form.email,
                code: form.code,
                new_password: form.newPassword,
            };

            const response = await api.post('/auth/reset-password', payload);

            if (response.data.success) {
                setSuccessMessage('Password updated successfully. You can now log in.');
                setStep(1); // Reiniciar el flujo
                setForm({ email: '', code: '', newPassword: '', confirmPassword: '' });
            } else {
                setErrorMessage(response.data.error || 'Error resetting the password.');
            }
        } catch (error) {
            setErrorMessage('Failed to connect to the server. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="forgot-password-container">
            <div className="forgot-password-box">
                <h1>Recover Password</h1>

                {/* ✅ Mensajes de éxito y error */}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}

                {/* Paso 1: Solicitar código */}
                {step === 1 && (
                    <form onSubmit={handleRequestCode}>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={form.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button" disabled={isLoading}>
                            {isLoading ? 'Sending...' : 'Send Code'}
                        </button>
                    </form>
                )}

                {/* Paso 2: Verificar código y restablecer contraseña */}
                {step === 2 && (
                    <form onSubmit={handleResetPassword}>
                        <div className="form-group">
                            <label htmlFor="code">Verification Code</label>
                            <input
                                type="text"
                                id="code"
                                name="code"
                                value={form.code}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newPassword">New Password</label>
                            <input
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                value={form.newPassword}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={form.confirmPassword}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button" disabled={isLoading}>
                            {isLoading ? 'Updating...' : 'Reset Password'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;

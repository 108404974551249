import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography, TextField, Alert, Select, MenuItem } from "@mui/material";
import api from "../services/api"; // Import API

const WithdrawModal = ({ open, onClose }) => {
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [withdrawType, setWithdrawType] = useState("normal");
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [withdrawSent, setWithdrawSent] = useState(false);
  const [addressLocked, setAddressLocked] = useState(false); // ✅ Lock address if registered

  // ✅ Fetch balance and withdrawal address when modal opens
  useEffect(() => {
    if (open) {
      // Fetch balance
      api.get("/balance/balance")
        .then((res) => {
          if (res.data.success && res.data.data.saldo !== undefined) {
            setBalance(res.data.data.saldo);
          } else {
            setBalance(0);
          }
        })
        .catch(() => setBalance(0));

      // ✅ Fetch registered withdrawal address
      api.get("/auth/direccion-retiro")
        .then((res) => {
          console.log("Response from /direccion-retiro:", res.data); // 👀 Debug
          if (res.data.success && res.data.data.direccion_retiro) {
            setDestinationAddress(res.data.data.direccion_retiro);
            setAddressLocked(true);
          } else {
            setDestinationAddress("");
            setAddressLocked(false);
          }
        })
        .catch(() => {
          setDestinationAddress("");
          setAddressLocked(false);
        });
    }
  }, [open]);

  // ✅ Calculate final amount after fee
  const getFinalAmount = () => {
    const feePercentage = withdrawType === "express" ? 0.15 : 0.10;
    return amount ? (amount - amount * feePercentage).toFixed(2) : "0.00";
  };

  // ✅ Check if balance is sufficient
  const isBalanceSufficient = () => {
    const feePercentage = withdrawType === "express" ? 0.15 : 0.10;
    const totalRequired = amount * (1 + feePercentage);
    return balance >= totalRequired;
  };

  // ✅ Submit withdrawal request
  const handleWithdraw = async () => {
    const numericAmount = Number(amount);

    if (numericAmount <= 0) {
      setResponseMessage({ type: "error", text: "The amount must be greater than zero." });
      return;
    }
    if (numericAmount < 10) {
      setResponseMessage({ type: "error", text: "Minimum withdrawal is 10 USDT." });
      return;
    }
    if (!isBalanceSufficient()) {
      setResponseMessage({ type: "error", text: "Insufficient balance for this withdrawal." });
      return;
    }
    if (!destinationAddress) {
      setResponseMessage({ type: "error", text: "You must enter a valid withdrawal address." });
      return;
    }

    setLoading(true);
    setResponseMessage(null);

    try {
      const response = await api.post("/withdraw/request", {
        amount,
        destination_address: destinationAddress,
        type: withdrawType,
      });

      if (response.data.success) {
        setWithdrawSent(true);
        setBalance(response.data.data.saldo_actual);
      } else {
        setResponseMessage({ type: "error", text: response.data.error || "Error processing the withdrawal." });
      }
    } catch (error) {
      setResponseMessage({ type: "error", text: error.response?.data?.error || "Connection error with the server." });
    }

    setLoading(false);
  };

  // ✅ Close modal and reset fields
  const handleClose = () => {
    setAmount("");
    setDestinationAddress("");
    setWithdrawType("normal");
    setResponseMessage(null);
    setWithdrawSent(false);
    setAddressLocked(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        {withdrawSent ? (
          // ✅ Message after withdrawal request
          <Box textAlign="center">
            <Typography variant="h6" color="success.main">
              ✅ Withdrawal successfully requested
            </Typography>
            <Typography variant="body1">
              Funds have been frozen until approval.
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold", mt: 1 }}>
              Current balance: ${balance}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleClose} sx={{ mt: 2 }}>
              Close
            </Button>
          </Box>
        ) : (
          // ✅ Withdrawal form
          <>
            <Typography variant="h6">Withdrawal Request</Typography>

            <Typography variant="body2" sx={{ mt: 1, mb: 2, fontWeight: "bold" }}>
              Available balance: ${balance}
            </Typography>

            <TextField
              label="Amount to withdraw"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              inputProps={{ min: "0", step: "0.01" }}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              disabled={loading}
            />

            <Select
              value={withdrawType}
              onChange={(e) => setWithdrawType(e.target.value)}
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading}
            >
              <MenuItem value="normal">Normal (10% Fee)</MenuItem>
              <MenuItem value="express">Express (15% Fee)</MenuItem>
            </Select>

            <TextField
              label="TRC20 Withdrawal Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={destinationAddress}
              onChange={(e) => setDestinationAddress(e.target.value)}
              disabled={loading || addressLocked} // Blocked if already registered
            />

            {/* ⚠️ Message if address is locked */}
            {addressLocked && (
              <Alert severity="info" sx={{ mt: 2 }}>
                ⚠️ To modify your withdrawal address, please contact support.
              </Alert>
            )}

            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Final amount to receive:</strong> ${getFinalAmount()}
            </Typography>

            {responseMessage && (
              <Alert severity={responseMessage.type} sx={{ mt: 2 }}>
                {responseMessage.text}
              </Alert>
            )}

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleWithdraw}
              disabled={loading || !amount || !destinationAddress || !isBalanceSufficient()}
              sx={{ mt: 2 }}
            >
              {loading ? "Processing..." : "Confirm Withdrawal"}
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

// ✅ Modal styles
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default WithdrawModal;

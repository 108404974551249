import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom"; // Para manejar parámetros de URL
import api from "../services/api";
import "../styles/Register.css";

const Register = () => {
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState({
    nombre: "",
    email: "",
    password: "",
    confirmPassword: "",
    codigo_referido: "",
    verificationCode: "",
  });

  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0); // Temporizador en segundos
  const [fieldErrors, setFieldErrors] = useState({}); // Validaciones de campos

  // Leer el código de referido del parámetro `ref` en la URL
  useEffect(() => {
    const refCode = searchParams.get("ref"); // Obtener el valor de `ref`
    if (refCode) {
      setForm((prevForm) => ({
        ...prevForm,
        codigo_referido: refCode, // Asignar el valor al campo correspondiente
      }));
    }
  }, [searchParams]);

  // Manejador para actualizar el formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    // Validaciones en tiempo real
    if (name === "email") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        email:
          !value.includes("@") || !value.endsWith(".com")
            ? "Email must be valid and end with .com."
            : "",
      }));
    } else if (name === "password" && value !== "") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        password:
          value.length < 8
            ? "Password must be at least 8 characters long."
            : "",
      }));
    } else if (name === "confirmPassword") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          value !== form.password ? "Passwords do not match." : "",
      }));
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  // Función para iniciar el temporizador
  // eslint-disable-next-line
  const startTimer = (duration) => {
    setTimer(duration);
  };

  // Temporizador para el código
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  // Validar si el email ya está registrado
  const validateEmail = async () => {
    try {
      const response = await api.post("/auth/validate-email", {
        email: form.email,
      });

      if (!response.data.success) {
        // Manejo del error devuelto por el backend
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          email: response.data.error, // Captura el mensaje de error del servidor
        }));
        return false;
      }

      return true; // Email válido
    } catch (err) {
      // Si ocurre un error inesperado (como conexión fallida)
      if (err.response && err.response.data) {
        setError(err.response.data.error); // Error del backend
      } else {
        setError("Failed to connect to the server. Please try again.");
      }
      return false;
    }
  };

  // Paso 1: Generar Código y Enviar al Email
  const handleGenerateCode = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Validar email antes de continuar
    if (fieldErrors.email || !form.email) {
      setError("Please fix email errors before continuing.");
      return;
    }

    const isValidEmail = await validateEmail();
    if (!isValidEmail) return; // Detener si el email no es válido

    try {
      setIsLoading(true);
      const response = await api.post(`/auth/generate-code`, {
        email: form.email,
      }); // Cambiado a POST con email en el body

      if (response.data.success) {
        // Mensaje para código ya generado o recién enviado
        if (response.data.success) {
          setSuccess(response.data.message.includes("ya generado")
            ? "A code has already been sent to your email. Please check."
            : "Code sent to your email. Please check.");
          setTimer(300); // 5 minutos
        }
        setStep(2);
      } else {
        setError(response.data.error || "Failed to send the code.");
      }
    } catch (err) {
      setError("Failed to connect to the server. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Paso 2: Verificar Código
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError(null);

    if (!form.verificationCode) {
      setError("Please enter the code received.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.post("/auth/verify-code", {
        email: form.email,
        code: form.verificationCode,
      });

      if (response.data.success) {
        setSuccess("Code verified successfully. Complete registration.");
        setStep(3);
      } else {
        setError(response.data.error || "Invalid code.");
        if (response.data.error === "El código ha expirado.") setStep(1);
      }
    } catch {
      setError("Failed to connect to the server. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Cancelar el proceso
  const handleCancel = () => {
    setForm({
      nombre: "",
      email: "",
      password: "",
      confirmPassword: "",
      codigo_referido: "",
      verificationCode: "",
    });

    setError(null);
    setSuccess(null);
    setTimer(0);
    setStep(1);
  };

  // Paso 3: Registrar Usuario
  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    // Validación adicional para el email antes del registro
    if (!form.email.includes("@") || !form.email.endsWith(".com")) {
      setError("Please enter a valid email ending in .com.");
      setIsLoading(false);
      return;
    }

    if (
      fieldErrors.password ||
      fieldErrors.confirmPassword ||
      !form.password ||
      !form.confirmPassword
    ) {
      setError("Please fix errors before continuing.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.post("/auth/register", {
        nombre: form.nombre,
        email: form.email,
        password: form.password,
        codigo_referido: form.codigo_referido,
      });

      if (response.data.success) {
         setSuccess("User successfully registered. Redirecting to login...");
        setTimeout(() => {
          window.location.href = "/login"; // Redirige al login después de 3 segundos
        }, 3000); // Retraso de 3 segundos para mostrar el mensaje de éxito
      } else {
        setError(response.data.error || "Error durante el registro.");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
        "Error al conectar con el servidor. Intente nuevamente."
      );
    } finally {
      setIsLoading(false);
    }
  };


  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="auth-container">
      <div className="form-box">
        <h1>Register</h1>
        <div className="progress-indicator">
          <span className={step >= 1 ? "active" : ""}>1. Email</span>
          <span className={step >= 2 ? "active" : ""}>2. Verify</span>
          <span className={step === 3 ? "active" : ""}>3. Register</span>
        </div>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        {step === 1 && (
          <form onSubmit={handleGenerateCode}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={form.email}
                onChange={handleInputChange}
                placeholder="Ingresa tu correo electrónico"
                required
              />
              {fieldErrors.email && (
                <p className="field-error">{fieldErrors.email}</p>
              )}
            </div>
            <button type="submit" className="register-button">
              Generate Code
            </button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleVerifyCode}>
            <div className="form-group">
              <label htmlFor="verificationCode">Verification Code</label>
              <input
                type="text"
                id="verificationCode"
                name="verificationCode"
                value={form.verificationCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <p className="timer">
              {timer > 0
                ? `Código válido por: ${formatTime()}`
                : "El código ha expirado."}
            </p>
            <div className="button-group">
              <button type="submit" className="register-button">Verify Code</button>
              <button type="button" className="resend-button" onClick={handleGenerateCode}>Resend Code</button>
              <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button>
            </div>
          </form>
        )}

        {step === 3 && (
          <form onSubmit={handleRegister}>
            <div className="form-group">
              <label htmlFor="nombre">Name</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={form.nombre}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={form.password}
                onChange={handleInputChange}
                required
              />
              {fieldErrors.password && (
                <p className="field-error">{fieldErrors.password}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={handleInputChange}
                required
              />
              {fieldErrors.confirmPassword && (
                <p className="field-error">{fieldErrors.confirmPassword}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="codigo_referido">Referral Code</label>
              <input
                type="text"
                id="codigo_referido"
                name="codigo_referido"
                value={form.codigo_referido}
                placeholder="Optional"
                readOnly={!!searchParams.get("ref")}
                onChange={handleInputChange}
              />
            </div>
            <div className="button-group">
              <button
                type="submit"
                className="register-button"
                disabled={isLoading}
              >
                {isLoading ? "Registering..." : "Register"}
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Register;

import axios from "axios";

// Configuración Global
//const BASE_URL_REF = "http://localhost:5000";
//const BASE_URL = "http://localhost:5000";  // 🔴 Cambia esto según el entorno de tu servidor
const BASE_URL_REF = "https://api.polarisex.com";
const BASE_URL = "https://api.polarisex.com";  // 🔴 Cambia esto según el entorno de tu servidor
const CRYPTOCOMPARE_API_KEY = "TU_API_KEY_AQUI";  // 🔴 Reemplaza con tu API Key de CryptoCompare
const CRYPTOCOMPARE_BASE_URL = "https://min-api.cryptocompare.com";  // 🔵 Base URL de CryptoCompare

// 🔄 Función para refrescar el token
export const refreshAuthToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return null;

    const response = await axios.post(`${BASE_URL}/auth/refresh`, {
      refresh_token: refreshToken,
    });

    if (response.data.success) {
      const { access_token } = response.data.data;
      localStorage.setItem("token", access_token);
      // Actualiza la cabecera en la instancia de api
      api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      return access_token;
    }

    return null;
  } catch (error) {
    console.error("Error refrescando el token:", error);
    return null;
  }
};

// Crear instancia de Axios para el backend
const api = axios.create({
  baseURL: BASE_URL,
});

// 🔄 Interceptor para agregar el token JWT
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newToken = await refreshAuthToken();
      if (newToken) {
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

// 🔹 Instancia de Axios para CryptoCompare
export const cryptoApi = axios.create({
  baseURL: CRYPTOCOMPARE_BASE_URL,
  params: {
    api_key: 'c9e059c2a57720cb0ef9c9e2be0b9a733e5e80eb0848a834b4f4584b0d8d41f8', // API Key agregada automáticamente
  },
});

export { BASE_URL, CRYPTOCOMPARE_API_KEY, CRYPTOCOMPARE_BASE_URL, BASE_URL_REF };
export default api;

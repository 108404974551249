import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Alert,
  IconButton,
  InputAdornment
} from "@mui/material";
import { FaCopy, FaCheck } from "react-icons/fa";
import api from "../services/api";

const DepositModal = ({ open, onClose }) => {
  const [amount, setAmount] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [copied, setCopied] = useState(false);
  const [depositSent, setDepositSent] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [reference, setReference] = useState(""); // ✅ New state for reference

  // Load deposit address when modal opens
  useEffect(() => {
    if (open) {
      api
        .get("/auth/deposit-address")
        .then((res) => {
          if (res.data.success && res.data.data.deposit_address) {
            setDepositAddress(res.data.data.deposit_address);
          } else {
            setDepositAddress("Not available");
          }
        })
        .catch(() => setDepositAddress("Error loading address"));
    }
  }, [open]);

  // Copy address
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(depositAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Handle deposit
  const handleDeposit = async () => {
    const numericAmount = Number(amount);
    if (numericAmount <= 0) {
      setResponseMessage({ type: "error", text: "Amount must be greater than zero." });
      return;
    }
    if (numericAmount < 5) {
      setResponseMessage({ type: "error", text: "Minimum deposit is 5 USDT." });
      return;
    }
    if (!reference) {
      setResponseMessage({ type: "error", text: "You must enter a deposit reference/ID." });
      return;
    }

    setLoading(true);
    setResponseMessage(null);

    try {
      const response = await api.post("/deposit/request", { amount, referencia: reference }); // ✅ Enviar referencia

      if (response.data.success) {
        setTransactionId(response.data.data.transaction_id);
        setDepositSent(true);
      } else {
        setResponseMessage({
          type: "error",
          text: response.data.error || "Error processing deposit"
        });
      }
    } catch (error) {
      setResponseMessage({
        type: "error",
        text: error.response?.data?.error || "Server connection error"
      });
    }

    setLoading(false);
  };

  // Reset modal
  const handleClose = () => {
    setAmount("");
    setReference(""); // ✅ Limpiar referencia
    setResponseMessage(null);
    setDepositSent(false);
    setTransactionId(null);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        {depositSent ? (
          // ✅ Success message
          <Box textAlign="center">
            <Typography variant="h6" color="success.main">
              ✅ Deposit request submitted
            </Typography>
            <Typography variant="body1">
              Your transaction ID is:
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold", mt: 1 }}>
              {transactionId}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Please wait for confirmation.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <>
            <Typography variant="h6">Deposit Funds</Typography>

            {/* Deposit address */}
            <TextField
              label="Deposit Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={depositAddress}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleCopyAddress}
                      disabled={!depositAddress || depositAddress === "Not available"}
                    >
                      {copied ? <FaCheck color="green" /> : <FaCopy />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            {/* Amount to deposit */}
            <TextField
              label="Deposit Amount"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              inputProps={{ min: "0", step: "0.01" }}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              disabled={loading}
            />

            {/* Reference input */}
            <TextField
              label="Deposit Reference / ID"
              variant="outlined"
              fullWidth
              margin="normal"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              disabled={loading}
            />

            {/* Response messages */}
            {responseMessage && (
              <Alert severity={responseMessage.type} sx={{ mt: 2 }}>
                {responseMessage.text}
              </Alert>
            )}

            {/* Confirm button */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDeposit}
              disabled={loading || !amount || !reference}
              sx={{ mt: 2 }}
            >
              {loading ? "Processing..." : "Confirm Deposit"}
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  textAlign: "center"
};

export default DepositModal;

import React, { createContext, useState, useEffect, useContext } from "react";
import api, { refreshAuthToken } from "../services/api"; // Importar refreshAuthToken

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const restoreSession = async () => {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refresh_token");
      const storedUser = localStorage.getItem("user");

      if (token && storedUser) {
        setUser(JSON.parse(storedUser));
        setIsAuthenticated(true);
        setIsAdmin(JSON.parse(storedUser)?.rol === "admin");

        // 🔄 Validamos si el token sigue siendo válido
        try {
          await api.get("/auth/me");
        } catch (error) {
          console.error("Error validando sesión:", error);
          if (refreshToken) {
            const newToken = await refreshAuthToken();
            if (!newToken) {
              logout();
            }
          } else {
            logout();
          }
        }
      }
      setLoading(false); // Se ejecuta siempre, ya sea que se renueve el token o se llame a logout
    };

    restoreSession();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await api.post("/auth/login", { email, password });

      if (response.data.success) {
        const { access_token, refresh_token, user } = response.data.data;

        // Guardar en localStorage
        localStorage.setItem("token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("user", JSON.stringify(user));

        // Actualizar estados
        setUser(user);
        setIsAuthenticated(true);
        setIsAdmin(user.rol === "admin");
      } else {
        throw new Error(response.data.error || "Credenciales incorrectas.");
      }
    } catch (error) {
      throw new Error(error.response?.data?.error || "Error al iniciar sesión.");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    setUser(null);
    setIsAuthenticated(false);
    setIsAdmin(false);
  };
  
  // Función de registro
  const register = async (nombre, email, password, codigoReferido) => {
    try {
      const response = await api.post('/auth/register', {
        nombre,
        email,
        password,
        codigo_referido: codigoReferido,
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Error durante el registro.');
      }

      return response.data.data; // Retorna los datos del registro si es exitoso
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al registrar usuario.');
    }
  };

  // Función de recuperación de contraseña
  const forgotPassword = async (email) => {
    try {
      const response = await api.post('/auth/forgot-password', { email });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Error al recuperar contraseña.');
      }

      return response.data.message; // Retorna el mensaje del servidor
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al recuperar contraseña.');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isAdmin,
        login,
        logout,
        register,
        forgotPassword,
        loading,
      }}
    >
      {!loading ? children : <div className="loading">Loading...</div>}
    </AuthContext.Provider>
  );
};

// Custom Hook para usar el contexto
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe usarse dentro de un AuthProvider');
  }
  return context;
};

import React, { useState, useEffect, useRef } from "react";
import { Wheel } from "react-custom-roulette";
import "../styles/LuckyWheel.css"; // Archivo de estilos mejorados

const wheelData = [
  { option: "Intente de nuevo", style: { backgroundColor: "#C70039" }, probability: 60 },
  { option: "1 USDT", style: { backgroundColor: "#33FF57" }, probability: 30 },
  { option: "Intente de nuevo", style: { backgroundColor: "#C70039" }, probability: 60 },
  { option: "2 USDT", style: { backgroundColor: "#3357FF" }, probability: 5 },
  { option: "3 USDT", style: { backgroundColor: "#FF5733" }, probability: 4 },
  { option: "Intente de nuevo", style: { backgroundColor: "#C70039" }, probability: 60 },
  { option: "1 USDT", style: { backgroundColor: "#33FF57" }, probability: 30 },
  { option: "50 USDT", style: { backgroundColor: "#FFC300" }, probability: 1 },
];

const MAX_SPINS_PER_DAY = 5;

const LuckyWheel = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [spins, setSpins] = useState(0);
  const [totalUSDT, setTotalUSDT] = useState(0);
  const [lastSpinDate, setLastSpinDate] = useState(null);
  const [message, setMessage] = useState(""); // Mensaje flotante

  const spinSoundRef = useRef(null);
  const winSoundRef = useRef(null);

  useEffect(() => {
    const storedPoints = localStorage.getItem("totalUSDT");
    const storedSpins = localStorage.getItem("spins");
    const storedLastSpinDate = localStorage.getItem("lastSpinDate");

    if (storedPoints && !isNaN(parseInt(storedPoints, 10))) {
      setTotalUSDT(parseInt(storedPoints, 10));
    }

    if (storedSpins && !isNaN(parseInt(storedSpins, 10))) {
      setSpins(parseInt(storedSpins, 10));
    }

    if (storedLastSpinDate) {
      setLastSpinDate(storedLastSpinDate);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("totalUSDT", totalUSDT);
    localStorage.setItem("spins", spins);
    localStorage.setItem("lastSpinDate", lastSpinDate);
  }, [totalUSDT, spins, lastSpinDate]);

  const resetSpinsIfNewDay = () => {
    const today = new Date().toLocaleDateString();
    if (lastSpinDate !== today) {
      setSpins(0);
      setLastSpinDate(today);
    }
  };

  useEffect(() => {
    resetSpinsIfNewDay();
    const interval = setInterval(() => {
      resetSpinsIfNewDay();
    }, 1000 * 60 * 60 * 24); // Cada 24 horas

    return () => clearInterval(interval);
  // eslint-disable-next-line
  }, [lastSpinDate]);

  const getRandomPrize = () => {
    const probabilityArray = [];
    wheelData.forEach((item, index) => {
      for (let i = 0; i < item.probability; i++) {
        probabilityArray.push(index);
      }
    });
    return probabilityArray[Math.floor(Math.random() * probabilityArray.length)];
  };

  const handleSpinClick = () => {
    resetSpinsIfNewDay();
    if (spins >= MAX_SPINS_PER_DAY) {
      setMessage("⏳ Has alcanzado el límite de giros por día. Vuelve mañana.");
      return;
    }

    const newPrizeNumber = getRandomPrize();
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
    setSpins(spins + 1);
    setLastSpinDate(new Date().toLocaleDateString());

    if (spinSoundRef.current) {
      spinSoundRef.current.play().catch((error) => console.error("Error al reproducir el sonido de giro:", error));
    }
  };

  const handleStopSpinning = () => {
    setMustSpin(false);
    const prize = wheelData[prizeNumber].option;
    let usdt = 0;

    if (prize.includes("USDT")) {
      usdt = parseInt(prize.split(" ")[0], 10);
      setTotalUSDT(totalUSDT + usdt);
      setMessage(`🎉 ¡Ganaste ${usdt} USDT!`);
    } else {
      setMessage("😞 ¡Intente de nuevo!");
    }

    if (winSoundRef.current && prize.includes("USDT")) {
      winSoundRef.current.play().catch((error) => console.error("Error al reproducir el sonido de premio:", error));
    }

    setTimeout(() => setMessage(""), 3000);
  };

  return (
    <div className="lucky-wheel-container">
      {message && <div className="wheel-message">{message}</div>}

      <h2 className="lucky-wheel-title">🎡 ¡Gira la Rueda de la Suerte! 🎡</h2>

      <div className="wheel-wrapper">
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={wheelData}
          onStopSpinning={handleStopSpinning}
          radiusLineColor={"#ffffff"}
          radiusColor={"#1e1e1e"}
          fontSize={18}
          outerBorderWidth={8}
          outerBorderColor={"#ffffff"}
          innerRadius={10}
          innerBorderColor={"#ffffff"}
        />
        <div className="wheel-pointer" aria-hidden="true"></div>
      </div>

      <button className={`spin-button ${mustSpin ? "loading" : ""}`} onClick={handleSpinClick} disabled={mustSpin}>
        {mustSpin ? "⏳ Girando..." : "🎰 Girar"}
      </button>

      <div className="points-display">
        <h3>💰 Total de USDT: <span>{totalUSDT}</span></h3>
        <h4>🎟️ Giros restantes: <span>{MAX_SPINS_PER_DAY - spins}</span></h4>
      </div>

      <audio ref={spinSoundRef}>
        <source src="/assets/sounds/spin.mp3" type="audio/mpeg" />
      </audio>
      <audio ref={winSoundRef}>
        <source src="/assets/sounds/win.mp3" type="audio/mpeg" />
      </audio>
    </div>
  );
};

export default LuckyWheel;

import React, { useEffect, useState } from "react";
import { FaArrowUp, FaArrowDown, FaChartLine } from "react-icons/fa";
import { useAuth } from "../auth/AuthContext";
import api, { cryptoApi } from "../services/api"; // 🔥 Importamos API
import { useNavigate } from "react-router-dom";
import TradeModal from "../components/TradeModal"; // Importamos el nuevo modal
import "../styles/Trade.css";

const CryptoScreen = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [cryptos, setCryptos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCrypto, setSelectedCrypto] = useState(null); // Criptomoneda seleccionada
  const [balance, setBalance] = useState(0); // Saldo actual del usuario
  const [successMessage, setSuccessMessage] = useState(null);
  const [operationResult, setOperationResult] = useState(null); // ✅ nuevo estado
  const [notificationKey, setNotificationKey] = useState(0);

  // Lista de criptos a solicitar
  const selectedCryptos = [
    "BTC",
    "ETH",    
    "SOL",
    "XRP",
    "DOGE",
    "ADA",    
  ];

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      fetchData();
    }
  }, [isAuthenticated, navigate]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      await Promise.all([fetchCryptos(), fetchBalance()]);
    } catch (error) {
      setError("Error al cargar datos. Revisa tu conexión.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCryptos = async () => {
    try {
      setLoading(true);

      // 🔥 Obtener datos de mercado con precios
      const response = await cryptoApi.get("/data/pricemultifull", {
        params: {
          fsyms: selectedCryptos.join(","), // Monedas específicas
          tsyms: "USD",
        },
      });

      // 🔥 Obtener datos de las criptos (incluyendo imágenes)
      const metadataResponse = await cryptoApi.get("/data/top/mktcapfull", {
        params: {
          limit: 10, // 🔥 Traer más datos en caso de que no todas las monedas aparezcan en el ranking
          tsym: "USD",
        },
      });

      // Convertir metadata en un mapa { "BTC": { imagen, nombre }, ... }
      const metadataMap = {};
      metadataResponse.data.Data.forEach((item) => {
        metadataMap[item.CoinInfo.Name] = {
          image: `https://www.cryptocompare.com${item.CoinInfo.ImageUrl}`,
          fullName: item.CoinInfo.FullName,
        };
      });

      // Formatear los datos combinando precios y metadata
      const formattedData = selectedCryptos.map((symbol) => ({
        id: symbol,
        name: metadataMap[symbol]?.fullName || symbol, // Nombre completo si existe, si no, usa el símbolo
        symbol,
        image: metadataMap[symbol]?.image || "", // Imagen si existe
        price: response.data.RAW[symbol]?.USD?.PRICE || 0,
        change24h: response.data.RAW[symbol]?.USD?.CHANGEPCT24HOUR || 0,
      }));

      setCryptos(formattedData);
    } catch (err) {
      console.error("Error al obtener criptomonedas:", err);
      setError("Error al obtener datos del mercado.");
    } finally {
      setLoading(false);
    }
  };

  const fetchOHLC = async (cryptoSymbol) => {
    try {
      const response = await cryptoApi.get("/data/v2/histoday", {
        params: {
          fsym: cryptoSymbol,
          tsym: "USD",
          limit: 20, // Últimos 20 días
        },
      });

      return response.data.Data.Data.map((entry) => ({
        x: new Date(entry.time * 1000), // Convertimos a fecha
        o: entry.open, // Open
        h: entry.high, // High
        l: entry.low, // Low
        c: entry.close, // Close
      }));
    } catch (error) {
      console.error(`Error al obtener OHLC para ${cryptoSymbol}:`, error);
      return [];
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await api.get("/balance/balance");
      if (response.data.success) {
        setBalance(response.data.data.saldo);
      } else {
        setError("Error al obtener el saldo.");
      }
    } catch (err) {
      console.error(err);
      setError("Error de conexión.");
    }
  };

  const handleOpenModal = async (crypto) => {
    setSelectedCrypto(null); // Limpia el estado anterior mientras carga la nueva cripto
    const ohlcData = await fetchOHLC(crypto.symbol); // 🔥 Cargar datos OHLC de la cripto seleccionada    
    setSelectedCrypto({ ...crypto, ohlc: ohlcData });
  };

  const handleCloseModal = () => {
    setSelectedCrypto(null);
  };

  const handleOperation = async (type, amount) => {
    if (!amount || isNaN(amount) || amount <= 0) {
      alert("Ingrese un monto válido.");
      return;
    }

    if (amount > balance) {
      alert("El monto supera tu saldo.");
      return;
    }

    try {
      const response = await api.post("/senales/operar", {
        criptomoneda: selectedCrypto.id,
        monto: parseFloat(amount),
        tipo: type,
      });

      if (response.data.success) {
        const isWinner = response.data.message.includes("ganadora");

        setSuccessMessage(
          `Operación ${isWinner ? "ganadora" : "perdedora"} realizada. Nuevo saldo: $${response.data.data.saldo_actual.toFixed(2)}`
        );

        setOperationResult(isWinner ? "success" : "error");
        setNotificationKey(prev => prev + 1); // Forzar renderización del mensaje
        setBalance(response.data.data.saldo_actual);

        // Limpiar notificación y cerrar modal
        setTimeout(() => {
          setSuccessMessage(null);
          setOperationResult(null);
          handleCloseModal(); // 👈 Cerrar el modal después de mostrar mensaje
        }, 12000);
      } else {
        alert(response.data.message || "Error en la operación.");
        handleCloseModal(); // Si falla, igual cerramos
      }
    } catch (err) {
      alert("Error en la conexión.");
      handleCloseModal(); // Si falla, igual cerramos
    }
  };

  return (
    <div className="trade-crypto-screen-container">
      {loading && <p className="trade-loading-text">Loading...</p>}
      {error && <p className="trade-error-text">{error}</p>}
      {successMessage && (
        <div
          key={notificationKey} // 👈 Forzar renderizado nuevo
          className={`trade-result-message ${operationResult === "success" ? "trade-success-message" : "trade-error-message"}`}
        >
          {successMessage}
        </div>
      )}
      <div className="trade-crypto-list">
        {cryptos.map((crypto) => (
          <div key={crypto.id} className="trade-crypto-item">
            {crypto.image ? ( // 🔥 Solo renderizar si hay imagen disponible
              <img src={crypto.image} alt={crypto.name} className="trade-crypto-logo" />
            ) : (
              <div className="trade-placeholder-logo">No Image</div> // 🔥 Mensaje alternativo si no hay imagen
            )}

            <div className="trade-crypto-info">
              <p className="trade-crypto-name">
                {crypto.name} ({crypto.symbol.toUpperCase()})
              </p>
              <p className="trade-crypto-price">
                {crypto.change24h >= 0 ? (
                  <FaArrowUp className="trade-positive" />
                ) : (
                  <FaArrowDown className="trade-negative" />
                )}
                ${crypto.price.toFixed(2)}
              </p>
            </div>
            <button
              className="trade-action-icon"
              onClick={() => handleOpenModal(crypto)}
            >
              <FaChartLine size={20} />
            </button>
          </div>
        ))}
      </div>

      {/* Renderiza el Modal solo si hay una criptomoneda seleccionada */}
      {selectedCrypto && (
        <TradeModal
          crypto={selectedCrypto}
          balance={balance}
          onClose={handleCloseModal}
          onTrade={handleOperation}
        />
      )}
    </div>
  );
};

export default CryptoScreen;

// src/pages/Profile.jsx

import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import api, { BASE_URL_REF } from '../services/api';
import '../styles/Profile.css';

const Profile = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [form, setForm] = useState({
    password: '',
    confirmPassword: '',
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [nivelInfo, setNivelInfo] = useState({
    nivelActual: '',
    progresoSaldo: 0,
    faltaReferidos: 0,
    faltaSaldo: 0,
    progresoReferidos: 0,
    siguienteNivel: '',
  });

  // Currency formatter
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const fetchProfile = async () => {
      try {
        setLoading(true);
        const response = await api.get('/auth/perfil');
        if (response.data.success) {
          setProfile(response.data.data);

          const { nivel, progreso } = response.data.data;

          setNivelInfo({
            nivelActual: nivel,
            progresoSaldo: progreso.porcentaje_saldo,
            faltaReferidos: progreso.faltante_referidos,
            faltaSaldo: progreso.faltante_saldo,
            progresoReferidos: progreso.progreso_referidos,
            siguienteNivel: progreso.siguiente_nivel,
          });
        } else {
          setError('Error loading profile data.');
        }
      } catch (err) {
        setError('Error connecting to the server. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [isAuthenticated, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Real-time validation
    if (name === 'password') {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        password:
          value.length < 8
            ? 'Password must be at least 8 characters.'
            : !/[A-Za-z]/.test(value) || !/\d/.test(value)
            ? 'Password must include letters and numbers.'
            : '',
      }));
    } else if (name === 'confirmPassword') {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          value !== form.password ? 'Passwords do not match.' : '',
      }));
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Final validation before submitting
    if (fieldErrors.password || fieldErrors.confirmPassword) {
      setError('Please fix the errors before continuing.');
      return;
    }

    try {
      setLoading(true);
      const response = await api.put('/auth/update-user', {
        usuario_id: profile.id,
        password: form.password,
      });

      if (response.data.success) {
        setSuccess('Password updated successfully.');
        setForm({ password: '', confirmPassword: '' });
        setFieldErrors({});
      } else {
        setError(response.data.error || 'Error updating password.');
      }
    } catch (err) {
      setError('Server connection error. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToClipboard = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => setSuccess('Copied to clipboard!'))
      .catch(() => setError('Failed to copy.'));
  };

  return (
    <div className="profile-container">
      {/* Notification Messages */}
      {success && <div className="notification success">{success}</div>}
      {error && <div className="notification error">{error}</div>}

      <div className="profile-grid">
        {/* User Info Card */}
        <div className="profile-card">
          <h2>User Information</h2>
          <div className="form-group">
            <label>UID</label>
            <input
              type="text"
              value={profile?.uid || ''}
              readOnly
              className="readonly-input"
            />
          </div>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={profile?.nombre || ''}
              readOnly
              className="readonly-input"
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={profile?.email || ''}
              readOnly
              className="readonly-input"
            />
          </div>
        </div>

        {/* Level & Progress Card */}
        <div className="nivel-card">
          <h2>Current Level: {nivelInfo.nivelActual}</h2>

          <div className="barra-progreso-container">
            <p>Balance Progress: {nivelInfo.progresoSaldo}%</p>
            <div className="barra-progreso">
              <div
                className="progreso"
                style={{ width: `${nivelInfo.progresoSaldo}%` }}
              ></div>
            </div>
          </div>

          <div className="barra-progreso-container">
            <p>Referral Progress: {nivelInfo.progresoReferidos}%</p>
            <div className="barra-progreso">
              <div
                className="progreso-referidos"
                style={{ width: `${nivelInfo.progresoReferidos}%` }}
              ></div>
            </div>
          </div>

          {nivelInfo.siguienteNivel ? (
            <p>
              You need {nivelInfo.faltaReferidos} more referrals and {formatCurrency(nivelInfo.faltaSaldo)} to reach {nivelInfo.siguienteNivel}.
            </p>
          ) : (
            <p>You've reached the maximum level!</p>
          )}
        </div>

        {/* Change Password Card */}
        <div className="profile-card">
          <h2>Change Password</h2>
          <form onSubmit={handleUpdateProfile}>
            <div className="form-group">
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={form.password}
                onChange={handleInputChange}
              />
              {fieldErrors.password && (
                <p className="field-error">{fieldErrors.password}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={handleInputChange}
              />
              {fieldErrors.confirmPassword && (
                <p className="field-error">{fieldErrors.confirmPassword}</p>
              )}
            </div>
            <button type="submit" className="update-button" disabled={loading}>
              {loading ? 'Updating...' : 'Update'}
            </button>
          </form>
        </div>

        {/* Referral Code Card */}
        <div className="profile-card">
          <h2>Referral Code</h2>
          <div className="form-group">
            <label>Referral Code</label>
            <div className="clipboard-group">
              <input
                type="text"
                value={profile?.codigo_referido || ''}
                readOnly
                className="readonly-input"
              />
              <button
                className="clipboard-button"
                onClick={() =>
                  handleCopyToClipboard(`${BASE_URL_REF}/register?ref=${profile?.codigo_referido}`)
                }
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>

        {/* TRC20 Address Card */}
        <div className="profile-card">
          <h2>TRC20 Address</h2>
          <div className="form-group">
            <label>TRC20 Address</label>
            <div className="clipboard-group">
              <input
                type="text"
                value={profile?.deposit_address || ''}
                readOnly
                className="readonly-input"
              />
              <button
                className="clipboard-button"
                onClick={() => handleCopyToClipboard(profile?.deposit_address || '')}
              >
                Copy Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
